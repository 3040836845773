import { Button, Col, Row, Space, Tag, Typography } from 'antd';
import { egeniePcTheme, RenderByCondition } from 'egenie-common';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { ENUM_MODULE_TYPE, ENUM_PROGRAMME_PLAN_TYPE } from '../constants';
import styles from './index.less';
import { Store } from './store';

@observer
class Header extends React.Component<{ store: Store; }> {
  render() {
    const {
      moduleType,
      handleModuleType,
      handleAddAndEditProgramme,
    } = this.props.store;
    return (
      <>
        <header className={styles.headerContainer}>
          {
            Object.values(ENUM_MODULE_TYPE)
              .map((item) => (
                <section
                  className={moduleType === item.value ? styles.active : undefined}
                  key={item.value}
                  onClick={() => handleModuleType(item.value)}
                >
                  {item.label}
                </section>
              ))
          }
        </header>
        <div style={{ margin: `${egeniePcTheme.spacing['egenie-spacing-xs']} 0` }}>
          <Button
            onClick={() => handleAddAndEditProgramme()}
            type="primary"
          >
            新建方案
          </Button>
        </div>
      </>
    );
  }
}

@observer
class ProgrammeList extends React.Component<{ store: Store; }> {
  render() {
    const {
      moduleProgrammeList,
      handleAddAndEditProgramme,
      handleProgrammeDelete,
    } = this.props.store;
    return (
      <Row
        gutter={[
          parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']),
          parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']),
        ]}
      >
        {
          moduleProgrammeList.map((item, index) => {
            const planTypeItem = Object.values(ENUM_PROGRAMME_PLAN_TYPE)
              .find((val) => _.toString(val.value) === _.toString(item.planType));
            return (
              <Col
                key={item.wmsModulePlanId}
                span={6}
                xxl={{ span: 4 }}
              >
                <div className={styles.programmeItem}>
                  <span className={styles.order}>
                    {index <= 8 ? `0${index + 1}` : index + 1}
                  </span>
                  <Typography.Title
                    ellipsis
                    level={3}
                    title={item.planName}
                  >
                    {item.planName}
                  </Typography.Title>
                  <section style={{ marginTop: parseFloat(egeniePcTheme.spacing['egenie-spacing-xss']) }}>
                    <Tag color={egeniePcTheme.color['egenie-primary-color']}>
                      {item.enable ? '已启用' : '未启用'}
                    </Tag>
                    <RenderByCondition show={Boolean(planTypeItem)}>
                      <Tag
                        color={planTypeItem?.color}
                        style={{
                          backgroundColor: planTypeItem?.backgroundColor,
                          color: planTypeItem?.color,
                        }}
                      >
                        {planTypeItem?.label}
                      </Tag>
                    </RenderByCondition>
                  </section>
                  <section
                    className={styles.description}
                    style={{ marginTop: parseFloat(egeniePcTheme.spacing['egenie-spacing-xs']) }}
                  >
                    <Typography.Text
                      ellipsis
                      title={item.deviceName}
                    >
                      设备:&nbsp;
                      {item.deviceName}
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      饱和容量:&nbsp;
                      {item.saturatedStockNum || 0}
                      件
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      饱和时效:&nbsp;
                      {item.saturationAging || 0}
                      件/小时
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      操作人数:&nbsp;
                      {item.operatorNum || 0}
                      人
                    </Typography.Text>
                  </section>
                  <section className={styles.description}>
                    <Typography.Text ellipsis>
                      人工成本:&nbsp;
                      {item.laborCost || 0}
                      元/天
                    </Typography.Text>
                  </section>
                  <Row justify="end">
                    <Space>
                      <Button
                        className="egenie-secondary-content"
                        onClick={() => handleAddAndEditProgramme(item)}
                        size="small"
                      >
                        编辑
                      </Button>
                      {
                        !item.enable ? (
                          <Button
                            onClick={() => handleProgrammeDelete(item)}
                            size="small"
                            type="default"
                          >
                            删除
                          </Button>
                        ) : null
                      }
                    </Space>
                  </Row>
                </div>
              </Col>
            );
          })
        }
      </Row>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Header store={store}/>
      <ProgrammeList store={store}/>
    </div>
  );
}
