import { Form, Input, InputNumber, Radio, Row, Select, Space, Drawer, Button } from 'antd';
import type { BaseData } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-common';
import { nanoid } from 'nanoid';
import React from 'react';
import { ENUM_PROGRAMME_PLAN_TYPE } from '../constants';
import styles from './index.less';
import type { ModuleProgrammeItem } from './types';

interface AddAndEditModalProps {
  title: string;
  onCancel: () => void;
  callback: (params: ModuleProgrammeItem) => Promise<unknown>;
  initialValues?: ModuleProgrammeItem;
  showPlanUse?: boolean;
  planUseData?: Array<{ value: number; label: string; }>;
}

export function AddAndEditModal({
  onCancel,
  callback,
  initialValues,
  title,
  showPlanUse,
  planUseData,
}: AddAndEditModalProps) {
  const deviceIdInitData = (initialValues?.deviceIds?.length > 0 ? initialValues.deviceIds : [undefined]).slice()
    .map((item) => (item ? `${item}` : item));
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: ModuleProgrammeItem; }>({
    loading: false,
    params: null,
  });

  const [
    deviceData,
    setDeviceData,
  ] = React.useState<ValueAndLabelData>([]);

  const [
    deviceIds,
    setDeviceIds,
  ] = React.useState<ModuleProgrammeItem['deviceIds']>(deviceIdInitData);

  function updateDeviceIds(index: number, id: string): void {
    const newData = deviceIds.slice();
    newData[index] = id;
    setDeviceIds(newData);
  }

  function addDeviceIds(): void {
    setDeviceIds(deviceIds.concat(undefined));
  }

  function deleteDeviceIds(deviceIndex: number): void {
    const newData = deviceIds.filter((notUse, index) => deviceIndex !== index);
    setDeviceIds(newData);
  }

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    request<BaseData<Array<{ deviceId: string; deviceName: string; }>>>({ url: '/api/cloud/wms/rest/cloud/report/device/queryIdAndName' })
      .then((info) => {
        setDeviceData((info.data || []).map((item) => ({
          value: `${item.deviceId}`,
          label: item.deviceName,
        })));
      });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback({
        ...paramData.params,
        deviceIds: deviceIds.filter(Boolean),
      })
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Drawer
      footer={(
        <Row justify="end">
          <Space>
            <Button onClick={() => onCancel()}>
              取消
            </Button>
            <Button
              loading={paramData.loading}
              onClick={() => form.submit()}
              type="primary"
            >
              确定
            </Button>
          </Space>
        </Row>
      )}
      maskClosable={false}
      onClose={() => onCancel()}
      title={title}
      visible
      width={480}
    >
      <div className={styles.addAndEditModalContainer}>
        <Form
          form={form}
          initialValues={{
            planType: ENUM_PROGRAMME_PLAN_TYPE.automatic.value,
            ...initialValues,
          }}
          layout="horizontal"
          onFinish={handleFinish}
        >
          <Form.Item
            label="方案名称"
            name="planName"
            rules={[
              {
                required: true,
                message: '请输入方案名称',
              },
            ]}
          >
            <Input
              allowClear
              autoFocus
              maxLength={25}
              placeholder="请输入方案名称"
            />
          </Form.Item>
          <Form.Item
            label="方案类型"
            name="planType"
            rules={[
              {
                required: true,
                message: '请输入方案类型',
              },
            ]}
          >
            <Radio.Group options={Object.values(ENUM_PROGRAMME_PLAN_TYPE)}/>
          </Form.Item>
          {
            showPlanUse ? (
              <Form.Item
                label="方案用途"
                name="planUse"
                rules={[
                  {
                    required: true,
                    message: '请输入方案用途',
                  },
                ]}
              >
                <Radio.Group options={Object.values(planUseData)}/>
              </Form.Item>
            ) : null
          }
          <Row align="middle">
            <div style={{ flexGrow: 1 }}>
              <Form.Item
                initialValue={deviceIds?.[0]}
                label="添加设备"
                name="deviceIds"
                rules={[
                  {
                    required: deviceIds.filter(Boolean).length === 0,
                    message: '请添加设备',
                  },
                ]}
              >
                <Select
                  allowClear
                  notFoundContent="没有数据"
                  onChange={(value) => updateDeviceIds(0, value as string)}
                  optionFilterProp="label"
                  options={deviceData}
                  placeholder="请添加设备"
                  showSearch
                />
              </Form.Item>
            </div>
            {
              deviceIds.filter(Boolean).length > 0 ? (
                <div
                  className={styles.addBtn}
                  onClick={addDeviceIds}
                >
                  <i className="icon-add"/>
                </div>
              ) : null
            }
          </Row>
          {
            deviceIds.slice(1)
              .map((item, index) => (
                <Row
                  align="middle"
                  key={nanoid()}
                >
                  <div style={{ flexGrow: 1 }}>
                    <Form.Item
                      initialValue={item}
                      label="设备"
                    >
                      <Select
                        allowClear
                        notFoundContent="没有数据"
                        onChange={(value) => updateDeviceIds(index + 1, value as string)}
                        optionFilterProp="label"
                        options={deviceData}
                        placeholder="请添加设备"
                        showSearch
                        value={item}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className={styles.deleteBtn}
                    onClick={() => deleteDeviceIds(index + 1)}
                  >
                    删除
                  </div>
                </Row>
              ))
          }
          <Space>
            <Form.Item
              label="操作人数"
              name="operatorNum"
              rules={[
                {
                  required: true,
                  message: '请输入操作人数',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={99}
                min={0}
                placeholder="请输入操作人数"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              人
            </div>
          </Space>
          <Space>
            <Form.Item
              label="人工成本"
              name="laborCost"
              rules={[
                {
                  required: true,
                  message: '请输入人工成本',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={9999999}
                min={0}
                placeholder="请输入人工成本"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              元/天
            </div>
          </Space>
          <Space>
            <Form.Item
              label="饱和容量"
              name="saturatedStockNum"
              rules={[
                {
                  required: true,
                  message: '请输入饱和容量',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入饱和容量"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              件
            </div>
          </Space>
          <Space>
            <Form.Item
              label="饱和时效"
              name="saturationAging"
              rules={[
                {
                  required: true,
                  message: '请输入饱和时效',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入饱和时效"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              件/小时
            </div>
          </Space>
        </Form>
      </div>
    </Drawer>
  );
}
