import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import { AddAndEditModal } from './addAndEditModal';
import { ENUM_MODULE_TYPE } from '../constants';
import type { ModuleProgrammeItem } from './types';

export class Store {
  constructor() {
    this.getModuleProgrammeList();
  }

  @observable public moduleType = ENUM_MODULE_TYPE.arrival.value;

  @action public handleModuleType = (moduleType: number): void => {
    if (this.moduleType !== moduleType) {
      this.moduleType = moduleType;
      this.getModuleProgrammeList();
    }
  };

  @observable public moduleProgrammeList: ModuleProgrammeItem[] = [];

  @action public getModuleProgrammeList = () => {
    request<BaseData<ModuleProgrammeItem[]>>({
      url: '/api/cloud/wms/rest/cloud/report/module/getModulePlanList',
      method: 'post',
      data: { moduleType: this.moduleType },
    })
      .then((info) => this.moduleProgrammeList = info.data || []);
  };

  @action public handleAddAndEditProgramme = async(item?: ModuleProgrammeItem) => {
    const isEdit = Boolean(item?.wmsModulePlanId);

    if (isEdit && item?.enable) {
      const isContinue = await new Promise((resolve) => {
        Modal.confirm({
          title: '方案已应用,确定编辑吗?',
          content: '编辑后会影响已经应用的网仓',
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });

      if (!isContinue) {
        return;
      }
    }

    const activeEnumItem = Object.values(ENUM_MODULE_TYPE).find((item) => _.toString(item.value) === _.toString(this.moduleType));
    let showPlanUse = false;
    let planUseDefault;
    let planUseData = [];
    if (activeEnumItem && 'showPlanUse' in activeEnumItem) {
      showPlanUse = activeEnumItem.showPlanUse;
      planUseData = activeEnumItem.planUseData;
      planUseDefault = activeEnumItem.planUseDefault;
    }

    renderModal(
      <AddAndEditModal
        callback={((data) => {
          return request({
            url: '/api/cloud/wms/rest/cloud/report/module/addOrEditModulePlan',
            method: 'post',
            data: {
              ...item,
              ...data,
              modulePlanId: item?.wmsModulePlanId,
              moduleType: this.moduleType,
            },
          })
            .then(() => {
              this.getModuleProgrammeList();
              message.success('操作成功');
              destroyAllModal();
            });
        })}
        initialValues={{
          planUse: planUseDefault,
          ...item,
        }}
        onCancel={destroyAllModal}
        planUseData={planUseData}
        showPlanUse={showPlanUse}
        title={isEdit ? '编辑方案' : '新建方案'}
      />
    );
  };

  @action public handleProgrammeDelete = (item: ModuleProgrammeItem): void => {
    Modal.confirm({
      content: '确定删除吗?',
      onOk: () => request({ url: `/api/cloud/wms/rest/cloud/report/module/deleteModulePlan/${item.wmsModulePlanId}` })
        .then(() => {
          this.getModuleProgrammeList();
          message.success('删除成功');
        }),
    });
  };
}

